import { Controller } from "@hotwired/stimulus"

const SCROLL_OFFSET_Y = 100

export default class extends Controller {
  scroll(event) {
    if (!event.params.selector) { return }

    const targetElement = document.querySelector(event.params.selector)
    if (!targetElement) { return }

    const rect = targetElement.getBoundingClientRect()
    const scrollTop = document.documentElement.scrollTop
    const targetTop = rect.top + scrollTop - SCROLL_OFFSET_Y

    window.scrollTo({
      behavior: "smooth",
      top: targetTop
    })

    targetElement.classList.add("animate__animated", "animate__flash", "animate__repeat-2")
    targetElement.addEventListener("animationend", () => targetElement.classList.remove(
      "animate__animated",
      "animate__flash",
      "animate__repeat-2"
    ))
  }
}
