import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    parameterName: String
  }

  reloadPage() {
    const url = new URL(window.location)
    const urlParams = new URLSearchParams(url.search)

    urlParams.set(this.parameterNameValue, this.element.value)
    url.search = urlParams.toString()

    window.location = url.toString()
  }
}
