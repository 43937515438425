import { Controller } from "@hotwired/stimulus"
import * as currency from "currency.js"

import { DELIMITERS, buildDelimiterRegex, getDelimiterSymbol } from "../src/currency_helper"

const buildCurrencyFormatter = delimiter => value => currency(value, { symbol: "", precision: 5, separator: "", decimal: delimiter })
const railsFormatter = buildCurrencyFormatter(DELIMITERS.dot)

export default class extends Controller {
  static targets = ["jsonInput"]

  parseValue(event) {
    const element = event.currentTarget
    const rawValue = element.value
    const delimiter = getDelimiterSymbol(element.value)
    const normalizedValue = rawValue.replaceAll(buildDelimiterRegex(delimiter), "")

    const parser = buildCurrencyFormatter(delimiter)
    const parsedValue = parser(normalizedValue)
    const railsFormattedValue = railsFormatter(parsedValue).format()

    const exchangeRateData = this.currentJsonData
    exchangeRateData[element.dataset.currency] = railsFormattedValue
    this.jsonInputTarget.value = JSON.stringify(exchangeRateData)
  }

  get currentJsonData() {
    try {
      return JSON.parse(this.jsonInputTarget.value)
    } catch(e) {
      return {}
    }
  }
}
