// Rails UJS (to use link_to with method: "XYZ")
import Rails from "@rails/ujs"
// Stimulus
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
// Various polyfills
import "../src/polyfills"

// Stimulus controllers
window.Stimulus = window.Stimulus || Application.start()

Stimulus.load(definitionsFromContext(require.context("../backend_controllers", true, /\.js$/)))

Rails.start()
