import { Controller } from "@hotwired/stimulus"

import { addClass, removeClass } from "../src/dom"
import { fetchHeaderConfiguration } from "../src/networking"

export default class extends Controller {
  static values = {
    url: String
  }

  // Lifecycle

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    const parsedData = JSON.parse(this.element.innerText)

    window.renderLegacyChart(
      parsedData.dateLabels,
      parsedData.data
    )
  }
}
