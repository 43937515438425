import { Controller } from "@hotwired/stimulus"
import Sortable from '@shopify/draggable/lib/sortable'

import { addClass, removeClass } from "../src/dom"
import { fetchHeaderConfiguration } from "../src/networking"

const DRAGGABLE_SELECTOR = ".sort-item"
const HANDLE_SELECTOR = ".handle"

const SORT_DELAY_AFTER_DROPPING_IN_MS = 250

const SORT_DIRECTION_UP = "up"
const SORT_DIRECTION_DOWN = "down"

export default class extends Controller {
  static targets = []
  static values = {
    url: String
  }

  // Lifecycle

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.draggedElement = null

    this.sortable = new Sortable(this.element, {
      draggable: DRAGGABLE_SELECTOR,
      handle: HANDLE_SELECTOR,
      classes: {
        "source:dragging": "is--drag-source",
        "draggable:over": "is--being-dragged-over",
        mirror: "is--dragged",
      },
      mirror: {
        xAxis: false,
      }
    })

    this.sortable.on("sortable:stop", (event) => {
      addClass(this.parentContainer, "is--loading")
      window.clearTimeout(this.sortTimeout)
      window.setTimeout(async () => { await this.sort(event) }, SORT_DELAY_AFTER_DROPPING_IN_MS)
    })
  }

  disconnect() {
    this.sortable.destroy()
  }

  // Events

  async moveItem(event) {
    addClass(this.parentContainer, "is--loading")

    const response = await fetch(event.params.url, {
      method: "POST",
      headers: fetchHeaderConfiguration(),
      credentials: "same-origin",
    })

    if (!response.ok) { return this.handleFetchError(response) }

    await this.updateView(response)
  }

  // Actions

  async sort(event) {
    const draggedElement = this.element.querySelectorAll(DRAGGABLE_SELECTOR)[event.data.newIndex]
    const direction = event.data.newIndex > event.data.oldIndex ? SORT_DIRECTION_DOWN : SORT_DIRECTION_UP

    const nextSibling = draggedElement.nextElementSibling
    const previousSibling = draggedElement.previousElementSibling

    let newPosition

    // Our new position is the position of the last sibling we moved out of the way. To find this properly, we need to differentiate between the direction our item was just moved since the relevant sibling is either the next or previous one, depending on the direction.
    if (direction == SORT_DIRECTION_UP) {
      if (nextSibling) {
        newPosition = parseInt(nextSibling.dataset.sortPosition)
      } else if (previousSibling) {
        newPosition = parseInt(previousSibling.dataset.sortPosition)
      }
    } else if(direction == SORT_DIRECTION_DOWN) {
      if (previousSibling) {
        newPosition = parseInt(previousSibling.dataset.sortPosition)
      } else if (nextSibling) {
        newPosition = parseInt(nextSibling.dataset.sortPosition)
      }
    }

    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: fetchHeaderConfiguration(),
      credentials: "same-origin",
      body: JSON.stringify({
        id: draggedElement.dataset.sortId,
        new_position: newPosition
      })
    })

    if (!response.ok) { return this.handleFetchError(response) }

    await this.updateView(response)
  }

  async updateView(response) {
    const newContent = await response.text()
    removeClass(this.parentContainer, "is--loading")
    this.element.outerHTML = newContent
  }

  handleFetchError(response) {
    console.error(response)
    removeClass(this.parentContainer, "is--loading")
  }

  // Getters

  get parentContainer() {
    return this.element.closest(".table-responsive, .panel")
  }
}
