export const DELIMITERS = {
  comma: ",",
  dot: "."
}

export const getDelimiterSymbol = (value) => {
  if (!value) { return DELIMITERS.comma }
  if (typeof value !== "string" ) { return DELIMITERS.comma }

  const numberOfCommas = value.split(DELIMITERS.comma).length - 1
  const numberOfDots = value.split(DELIMITERS.dot).length - 1

  // If there are neither dots nor commas, we just assume the comma as it doesn't matter right now.
  if (numberOfCommas === 0 && numberOfDots === 0) {
    return DELIMITERS.comma
  // If there is only one comma, that's it.
  } else if (numberOfCommas === 1 && numberOfDots === 0) {
    return DELIMITERS.comma
  // If there is only one dot, that's it.
  } else if (numberOfCommas === 0 && numberOfDots === 1) {
    return DELIMITERS.dots
  // If there are more commas than dots, the commas are thousand dividers, so the decimal delimiter is the dot.
  } else if (numberOfCommas > numberOfDots) {
    return DELIMITERS.dot
  // The other way around.
  } else if (numberOfDots > numberOfCommas) {
    return DELIMITERS.comma
  }

  // If there is the same amount of each, we take the one that is further to the right.
  const lastComma = value.lastIndexOf(DELIMITERS.comma)
  const lastDot = value.lastIndexOf(DELIMITERS.dot)

  if (lastComma > lastDot) {
    return DELIMITERS.comma
  } else {
    return DELIMITERS.dot
  }
}

export const buildDelimiterRegex = (delimiter) => {
  if (delimiter == DELIMITERS.comma) {
    return /[^0-9,]/gi
  } else {
    return /[^0-9\.]/gi
  }
}
