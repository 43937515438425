import { Controller } from "@hotwired/stimulus"

import { getCsrfToken } from "../src/networking"

export default class extends Controller {
  static values = {
    url: String
  }

  // Lifecycle

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.loadData()
  }

  // Events

  async loadData() {
    const response = await fetch(this.urlValue, {
      method: "GET",
      headers: {
        "Content-Type": "text/html",
        "Accept": "text/html",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-TOKEN": getCsrfToken(),
      },
      credentials: "same-origin",
    })

    if (!response.ok) { return this.handleFetchError(response) }

    await this.renderContent(response)
  }

  // Actions

  async renderContent(response) {
    const newContent = await response.text()
    this.element.innerHTML = newContent
  }

  handleFetchError(response) { console.error(response) }
}
